<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    value: {
        type: String,
        default: undefined,
    },
    name: {
        type: String,
        required: true,
    },
});
const name = toRef(props, "name");

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.value,
    validateOnValueUpdate: false,
});
</script>

<template>
    <div>
        <VueDatePicker
            :name="name"
            v-bind="$attrs"
            v-model="inputValue"
            model-type="yyyy-MM-dd"
            :on-update:model-value="handleChange"
            locale="ru"
            format="yyyy-MM-dd"
            select-text="Выбрать"
            cancel-text="Отмена"
            input-class-name="datepicker__input"
        ></VueDatePicker>
        <slot></slot>
    </div>
</template>

<style lang="scss">
.datepicker__input {
    padding: 6px 32px !important;
}
</style>
